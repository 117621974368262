import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'eventManagement',
    initialState: {modalIsOpen: false, selectedProjectId: undefined},
    reducers: {
        setEventModalIsOpen: (state, {payload}) => {
            state.modalIsOpen = payload
        },
        setEventSelectedProjectId: (state, {payload}) => {
            state.selectedProjectId = payload
        }
    }
});

export default slice.reducer;

export const {setEventModalIsOpen, setEventSelectedProjectId} = slice.actions;

export const selectModalIsOpen = state => state.eventManagement.modalIsOpen;
export const selectSelectedProjectId = state => state.eventManagement.selectedProjectId;