import React, {useEffect, useRef, useState} from 'react';

const SearchBar = ({onChange, placeholder}) => {
    const searchRef = useRef();
    const [search, setSearch] = useState();
    const containerClassName = "container-search";

    useEffect(() => {
        searchRef?.current?.focus();
    }, []);

    const handleOnClick = event => {
        if (event.target.className !== containerClassName) return;

        onChange(search);
    };

    const handleOnChange = event => {
        setSearch(event.target.value);
    };

    const handleOnKeyPress = event => {
        if (event.key === 'Enter') {
            onChange(search);
        }
    };

    return (
        <div className={containerClassName} onClick={handleOnClick}>
            <input className='input-text' type='text' value={search} onChange={handleOnChange}
                   onKeyPress={handleOnKeyPress} ref={searchRef}
                   placeholder={placeholder}/>
        </div>
    );
};

export default SearchBar;