import {api} from "../app/SynappApi";
import {TAGS} from "../config/constants";
import {getProvidedTags, objectToQueryStrings} from "../utils/query-utils";

const taskApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTasks: builder.query({
            query: (params) => `/api/services/app/Task/GetAll${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result,
            providesTags: result => getProvidedTags(result.items, TAGS.TASKS)
        }),
        getTask: builder.query({
            query: (id) => `api/services/app/Task/Get?id=${id}`,
            transformResponse: (response) => response.result,
            providesTags: (result, error, id) => [{type: TAGS.TASKS, id}],
        }),
        getTaskSelectItems: builder.query({
            query: () => 'api/services/app/Task/GetAllSelectItems',
            transformResponse: (response) => response.result.items,
            providesTags: result => getProvidedTags(result, TAGS.TASK_LIST_ITEMS, true)
        }),
        createTask: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Task/Create',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.TASKS, id: TAGS.DEFAULT}, {type: TAGS.TASK_LIST_ITEMS, id: TAGS.DEFAULT}],
        }),
        updateTask: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Task/Update',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.TASKS, id}, {type: TAGS.TASK_LIST_ITEMS, id}],
        }),
        deleteTask: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/Task/Delete?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{type: TAGS.TASKS, id}, {type: TAGS.TASK_LIST_ITEMS, id}],
        }),
        updateTaskStatus: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Task/UpdateStatus',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.TASKS, id}, {type: TAGS.TASK_LIST_ITEMS, id}],
        })
    })
});

export const {
    useGetTasksQuery,
    useGetTaskQuery,
    useGetTaskSelectItemsQuery,
    useCreateTaskMutation,
    useUpdateTaskMutation,
    useUpdateTaskStatusMutation,
    useDeleteTaskMutation
} = taskApi;