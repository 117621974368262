import React, {lazy, useEffect} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import RequireAuth from "./RequireAuth";
import {PERMISSION} from "../config/constants";
import {useIsTenantAvailableMutation} from "../api/AuthenticationApi";
import {manageTenant, manageTenantRouting, TENANCY_NAME} from "../utils/tenant-utils";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Login = lazy(() => import("../pages/Authorization/Login"));
const ForgotPassword = lazy(() => import("../pages/Authorization/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/Authorization/ResetPassword"));
const Project = lazy(() => import("../pages/Projects/Project"));
const Contact = lazy(() => import("../pages/Contacts/Contact"));
const ContactDetails = lazy(() => import("../pages/Contacts/ContactDetails"));
const ProjectDetails = lazy(() => import("../pages/Projects/ProjectDetails"));
const ProjectOverview = lazy(() => import("../pages/Projects/ProjectOverview"));
const Task = lazy(() => import("../pages/Tasks/Task"));
const TaskDetails = lazy(() => import("../pages/Tasks/TaskDetails"));
const Event = lazy(() => import("../pages/Events/Event"));
const EventDetails = lazy(() => import("../pages/Events/EventDetails"));
const Timesheet = lazy(() => import("../pages/Timesheet"));
const Invoice = lazy(() => import("../pages/Invoice"));
const Register = lazy(() => import("../pages/Authorization/Register"));
const Timeline = lazy(() => import("../pages/Timeline"));
const UserSettings = lazy(() => import("../pages/Authorization/UserSettings"));
const Subscription = lazy(() => import("../pages/Authorization/Subscription"));
const VerbalReportDetails = lazy(() => import("../pages/VerbalReports/NewVerbalReportDetails"));

const AuthenticationLayout = lazy(() => import('../components/layout/Authentication/Layout'));
const Layout = lazy(() => import('../components/layout/Main'));

const authProtectedRoutes = [{
    path: "/dashboard",
    translationKey: "header.header_menu.dashboards",
    component: <Dashboard/>,
    sidebarClassName: 'btn-home',
    permission: '',
    className: 'home',
    order: 0
}, {
    path: "/projects",
    translationKey: 'header.header_menu.projects',
    component: <Project/>,
    permission: [PERMISSION.PAGE_PROJECTS],
    sidebarClassName: 'btn-projects',
    className: 'projects',
    order: 1
}, {
    path: "/project/:id",
    translationKey: '',
    component: <ProjectDetails/>,
    permission: [PERMISSION.PAGE_PROJECTS, PERMISSION.CREATE_PROJECT, PERMISSION.UPDATE_PROJECT],
    className: ''
}, {
    path: "/project-overview/:id",
    translationKey: '',
    component: <ProjectOverview/>,
    permission: [PERMISSION.PAGE_PROJECTS],
    className: ''
}, {
    path: "/events",
    translationKey: 'header.header_menu.events',
    component: <Event/>,
    permissions: [PERMISSION.PAGE_EVENTS],
    className: 'events',
    sidebarClassName: 'btn-events',
    order: 3
}, {
    path: "/event/:id",
    translationKey: '',
    component: <EventDetails/>,
    permissions: [PERMISSION.PAGE_EVENTS],
    className: '',
}, {
    path: "/contacts",
    translationKey: 'header.header_menu.contacts',
    component: <Contact/>,
    permissions: [PERMISSION.PAGE_CONTACTS],
    className: 'contact',
    sidebarClassName: 'btn-contact',
    order: 2
}, {
    path: "/contact/:id",
    translationKey: '',
    component: <ContactDetails/>,
    permissions: [PERMISSION.PAGE_CONTACTS],
    className: ''
}, {
    path: "/timeline",
    translationKey: 'header.header_menu.timeline',
    component: <Timeline/>,
    permissions: [PERMISSION.PAGE_TIMELINE],
    className: 'timeline',
    sidebarClassName: 'btn-timeline',
    order: 7
}, {
    path: "/tasks",
    translationKey: 'header.header_menu.tasks',
    component: <Task/>,
    permissions: [PERMISSION.PAGE_TASKS],
    className: 'tasks',
    sidebarClassName: 'btn-task',
    order: 4
}, {
    path: "/task/:id",
    translationKey: '',
    component: <TaskDetails/>,
    permissions: [PERMISSION.PAGE_TASKS, PERMISSION.CREATE_TASK, PERMISSION.UPDATE_TASK],
    className: ''
}, {
    path: "/timesheets",
    translationKey: 'header.header_menu.timesheet',
    component: <Timesheet/>,
    permissions: [PERMISSION.PAGE_TIME_SHEETS],
    className: 'timesheet',
    sidebarClassName: 'btn-timesheet',
    order: 5
}, {
    path: "/invoices",
    translationKey: 'header.header_menu.invoices',
    component: <Invoice/>,
    permissions: [PERMISSION.PAGE_INVOICES],
    className: 'invoicing',
    sidebarClassName: 'btn-invoicing',
    order: 6
}, {
    path: "/user/settings",
    translationKey: '',
    component: <UserSettings/>,
    permissions: [PERMISSION.PAGE_USER_SETTINGS],
    className: ''
}, {
    path: "/verbal-report/:id",
    translationKey: '',
    component: <VerbalReportDetails/>,
    permissions: [PERMISSION.PAGE_VERBAL_REPORTS, PERMISSION.CREATE_VERBAL_REPORT, PERMISSION.UPDATE_VERBAL_REPORT],
    className: ''
}];

const SynappRoutes = () => {
    const [isTenantAvailable] = useIsTenantAvailableMutation();

    useEffect(async () => {
        await manageTenant(isTenantAvailable, TENANCY_NAME);
    }, [TENANCY_NAME]);

    const dashboardPath = manageTenantRouting("/dashboard");
    const routes = authProtectedRoutes.map(route => ({...route, path: manageTenantRouting(route.path)}));

    return (<Routes>
        <Route element={<AuthenticationLayout/>}>
            <Route path="/forgot-password" element={<ForgotPassword/>}/>
            <Route path="/reset-password" element={<ResetPassword/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/login" element={<Login/>}/>
        </Route>
        <Route element={<AuthenticationLayout className="main container-extra-fluid"/>}>

            <Route path="/subscription" element={<Subscription/>}/>
        </Route>
        <Route element={<Layout/>}>
            {routes.map(route => <Route path={route.path} key={route.path}
                                        element={<RequireAuth
                                            permissions={route.permissions}>{route.component}</RequireAuth>}/>)}
        </Route>
        <Route path="/" exact element={<Navigate replace to={dashboardPath}/>}/>
        <Route path='*' element={<Navigate replace to={dashboardPath}/>}/>
    </Routes>);
};

export default SynappRoutes;

export {authProtectedRoutes};