import {getContactTypeLabel} from "./contact-utils";
import {EVENT_STATUS, EVENT_TYPE, STATUS} from "../config/constants";

export const groupedAttendeesOptions = (users, contacts) => [
    {
        label: "Utilisateurs",
        options: users?.map(({value, text}) => ({value: `userIds-${parseInt(value)}`, label: text})) || []
    },
    {
        label: "Contacts",
        options: contacts?.map(({value, type, text}) => ({
            value: `contactIds-${parseInt(value)}`,
            label: `${getContactTypeLabel(parseInt(type))} - ${text}`
        }))
    }
];

export const getEventStatusLabel = status => {
    switch (status) {
        case EVENT_STATUS.TO_COME:
            return "À venir";
        case EVENT_STATUS.IN_PROGRESS:
            return "En cours";
        case EVENT_STATUS.COMPLETED:
            return "Terminé";
        default:
            return "À venir";
    }
};

export const getEventTypeLabel = status => {
    switch (status) {
        case EVENT_TYPE.UNKNOWN:
            return "Non défini";
        case EVENT_TYPE.SITE_SURVEY:
            return "Relevé sur site";
        case EVENT_TYPE.CLIENT_MEETING:
            return "Réunion client";
        case EVENT_TYPE.TEAM_MEETING:
            return "Réunion d’équipe";
        case EVENT_TYPE.ADMINISTRATION_MEETING:
            return "Réunion administration";
        case EVENT_TYPE.CONSTRUCTION_MEETING:
            return "Réunion de chantier";
        case EVENT_TYPE.PROVISIONAL_ACCEPTANCE:
            return "Réception provisoire";
        case EVENT_TYPE.FINAL_ACCEPTANCE:
            return "Réception définitive";
        default:
            return "Non défini";
    }
};
