import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useCreateEventMutation} from "../../api/EventApi";
import {setSelectedProjectId} from "../Contact/contactSlice";
import {setEventSelectedProjectId} from "./eventSlice";
import {DEFAULT_CONTACT, DEFAULT_EVENT, EVENT_STATUS_OPTIONS, EVENT_TYPE_OPTIONS} from "../../config/constants";
import useFormValidation from "../../hooks/useFormValidation";
import {toast} from "react-toastify";
import Modal from "react-modal";
import SelectWithLabel from "../interactive/SelectWithLabel";
import {useGetContactSelectItemsQuery} from "../../api/ContactApi";
import {useGetUserSelectItemsQuery} from "../../api/UserApi";
import {groupedAttendeesOptions} from "../../utils/event-utils";
import InputWithLabel from "../interactive/InputWithLabel";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import {useGetVerbalReportTemplateSelectItemsQuery} from "../../api/VerbalReportApi";

const CreateEventModal = ({isOpen, onRequestClose, projectId}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const [createEvent, {isLoading}] = useCreateEventMutation();
    const {data: contacts} = useGetContactSelectItemsQuery("", {skip: !isOpen});
    const {data: users} = useGetUserSelectItemsQuery("", {skip: !isOpen});
    const attendeesOptions = groupedAttendeesOptions(users, contacts);
    const {data: verbalReportTemplates} = useGetVerbalReportTemplateSelectItemsQuery("", {skip: !isOpen});

    useEffect(() => {
        return () => {
            dispatch(setEventSelectedProjectId(undefined));
        };
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setValues({...DEFAULT_EVENT})
            setSelectedAttendees([]);
        } else if (!!projectId) setValues({...DEFAULT_EVENT, projectId: parseInt(projectId)});
    }, [isOpen]);

    const handleSubmit = async values => {
        try {
            await createEvent(values).unwrap();
            setValues(DEFAULT_EVENT);
            setSelectedAttendees([]);
            dispatch(setSelectedProjectId(undefined));
            onRequestClose();
        } catch (error) {
            console.error(error);
            toast.error(t('event.form.error.event_not_created'));
        }
    };

    const validateForm = values => {
        let errors = {};
        if (!values.name) {
            errors.name = "Veuillez entrer un nom";
        }

        if (!values.startDate) {
            errors.startDate = "Veuillez entrer une date de début";
        }

        if (!values.endDate) {
            errors.endDate = "Veuillez entrer une date de fin";
        }

        return errors;
    };

    const filterEndDateTime = (date) => {
        if (!values.startDate) return true;
        const isPastTime = values.startDate.getTime() > date.getTime();
        return !isPastTime;
    };

    const filterStartDateTime = (date) => {
        if (!values.endDate) return true;
        const isPastTime = values.endDate.getTime() < date.getTime();
        return !isPastTime;
    };

    const {
        values, errors, handleOnChange, handleOnSelectChange, handleOnDateChange, handleOnSubmit, setValues
    } = useFormValidation({...DEFAULT_CONTACT, projectId}, validateForm, handleSubmit);


    const handleOnChangeAttendees = newValue => {
        setSelectedAttendees([...newValue]);
        const _values = newValue?.map(v => v.value);
        const userIds = _values.filter(value => value.startsWith("userIds-")).map(value => parseInt(value.split("userIds-")[1]));
        const contactIds = _values.filter(value => value.startsWith("contactIds-")).map(value => parseInt(value.split("contactIds-")[1]));
        setValues({...values, userIds, contactIds});
    };

    const templateOptions = verbalReportTemplates?.map(({value, text}) => ({
        value: parseInt(value),
        label: text
    })) || [];

    return (
        <Modal
            isOpen={isOpen} className="modal modal-task modal-contact" overlayClassName="modal-overlay"
            contentLabel="Event Modal" onRequestClose={onRequestClose}>
            <div className="container">
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <div className='container-shadow form row'>
                            <div className='col-12 form-heading'>
                                <h3>
                                    Créer un événement
                                </h3>
                                <button className='btn-close' onClick={onRequestClose}/>
                            </div>

                            <InputWithLabel label="Nom" id="name" values={values} tabIndex="1"
                                            onChange={handleOnChange}
                                            placeholder="Veuillez entrer un nom d'événement..."
                                            className={classNames('input-text', {"is-invalid": errors?.name})}/>

                            {errors?.name && <span className="text-danger">{errors.name}</span>}

                            <SelectWithLabel label="Type" options={EVENT_TYPE_OPTIONS}
                                             placeholder="Veuillez choisir le type de l'événement..."
                                             values={values} name="type"
                                             onChange={handleOnSelectChange} tabIndex="2"/>

                            <SelectWithLabel label="Participants" options={attendeesOptions}
                                             placeholder="Veuillez choisir les participants de l'événement..."
                                             values={values}
                                             isClearable
                                             isMulti
                                             tabIndex="3"
                                             selectedValue={selectedAttendees}
                                             onChange={handleOnChangeAttendees}/>


                            <label className='label-component'>
                                Date de début de l'événement
                            </label>
                            <div className='container-date'>
                                <DatePicker
                                    isClearable
                                    className={classNames('input-text', {"is-invalid": errors?.startDate})}
                                    name="startDate"
                                    locale="fr"
                                    showTimeSelect
                                    timeFormat="p"
                                    dateFormat="Pp"
                                    tabIndex="4"
                                    timeCaption="Heures"
                                    filterTime={filterStartDateTime}
                                    maxDate={values["endDate"]}
                                    onChange={value => handleOnDateChange(value, "startDate")}
                                    placeholderText="Veuillez entrer une date de début..."
                                    selected={values["startDate"]}/>
                            </div>
                            {errors?.startDate && <span className="text-danger">{errors.startDate}</span>}

                            <label className='label-component'>
                                Date de fin de l'événement
                            </label>
                            <div className='container-date'>
                                <DatePicker
                                    isClearable
                                    className={classNames('input-text', {"is-invalid": errors?.endDate})}
                                    locale="fr"
                                    showTimeSelect
                                    name="endDate"
                                    timeFormat="p"
                                    dateFormat="Pp"
                                    timeCaption="Heures"
                                    tabIndex="5"
                                    filterTime={filterEndDateTime}
                                    minDate={values["startDate"]}
                                    onChange={value => handleOnDateChange(value, "endDate")}
                                    placeholderText="Veuillez entrer une date de fin..."
                                    selected={values["endDate"]}/>
                            </div>
                            {errors?.endDate && <span className="text-danger">{errors.endDate}</span>}


                            <SelectWithLabel label="Modèle procès-verbal" options={templateOptions}
                                             placeholder="Veuillez choisir le modèle de procès-verbal..."
                                             values={values} name="verbalReportTemplateId"
                                             error={errors?.verbalReportTemplateId}
                                             isClearable
                                             onChange={handleOnSelectChange} tabIndex="6"/>


                            <SelectWithLabel label="Statut" options={EVENT_STATUS_OPTIONS}
                                             placeholder="Veuillez choisir le statut de l'événement..."
                                             values={values} name="status"
                                             onChange={handleOnSelectChange} tabIndex="7"/>

                            <div className='col-12 btn-group text-center'>
                                <button className='btn-ternary' onClick={handleOnSubmit} disabled={isLoading}>
                                    enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateEventModal;