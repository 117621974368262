import React from 'react';

const InputWithLabel = ({label, id, values, ...rest}) => {
    return (<div className="mb-15 w-100 container-text">
        {label && <label className='label-component'>
            {label}
        </label>}
        <input id={id} name={id} value={values?.hasOwnProperty(id) ? values[id] : ""} type="text"
               className="input-text" {...rest} />
    </div>);
};

export default InputWithLabel;