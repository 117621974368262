import React, {useEffect, useState} from 'react';
import {createPortal} from "react-dom";

const Title = ({titleOutletId = "default-title", children}) => {
    const [titleOutlet, setTitleOutlet] = useState();

    useEffect(() => {
        const outletElement = document.getElementById(titleOutletId);
        if (outletElement) {
            setTitleOutlet(outletElement);
        }
    }, [titleOutletId]);

    if (!titleOutlet) return null;

    return createPortal(
        <h2 className="section-title">{children}</h2>
        , titleOutlet);
};

export default Title;