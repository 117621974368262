import React from 'react';
import Select from "react-select";
import {CONTACT_TYPE_OPTIONS} from "../../../../config/constants";
import classNames from "classnames";

const ContactFiltersList = (props) => {

    const {
        enterpriseNameFilters,
        selectedEnterpriseName,
        handleOnChangeEnterpriseName,
        selectedType,
        handleOnChangeType,
        useRowSelect
    } = props;

    const className = classNames({'col-md-4': !useRowSelect, 'col-md-5': useRowSelect});

    return (
        <div className='row'>
            <div className={className}>
                <Select className="container-select w-100" options={enterpriseNameFilters} isClearable
                        value={selectedEnterpriseName}
                        onChange={handleOnChangeEnterpriseName}
                        placeholder="Société"/>
            </div>
            <div className={className}>
                <Select className="container-select w-100" options={CONTACT_TYPE_OPTIONS} isClearable
                        value={selectedType}
                        onChange={handleOnChangeType}
                        placeholder="Profession"/>
            </div>
            <div className={classNames({'col-md-4': !useRowSelect, 'col-md-2': useRowSelect})}/>
        </div>
    );
};

export default ContactFiltersList;