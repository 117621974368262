import {api} from "../app/SynappApi";
import {getProvidedTags, objectToQueryStrings} from "../utils/query-utils";
import {TAGS} from "../config/constants";

const verbalReportApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getVerbalReports: builder.query({
            query: (params) => `/api/services/app/VerbalReport/GetAll${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result,
            providesTags: result => getProvidedTags(result.items, TAGS.VERBAL_REPORTS)
        }),
        getVerbalReportTemplates: builder.query({
            query: (params) => `/api/services/app/VerbalReport/GetVerbalReportTemplates${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result,
            providesTags: result => getProvidedTags(result.items, TAGS.VERBAL_REPORT_TEMPLATES)
        }),
        getVerbalReportTemplateSelectItems: builder.query({
            query: () => 'api/services/app/VerbalReport/GetVerbalReportTemplateSelectItems',
            transformResponse: (response) => response.result.items,
            providesTags: (result) => getProvidedTags(result, TAGS.VERBAL_REPORT_TEMPLATES_LIST_ITEMS, true)
        }),
        getVerbalReport: builder.query({
            query: (id) => `api/services/app/VerbalReport/Get?id=${id}`,
            transformResponse: (response) => response.result,
            providesTags: (result, error, id) => [{type: TAGS.VERBAL_REPORTS, id}],
        }),
        getVerbalReportTemplate: builder.query({
            query: (id) => `api/services/app/VerbalReport/GetVerbalReportTemplate?id=${id}`,
            transformResponse: (response) => response.result,
            providesTags: (result, error, id) => [{type: TAGS.VERBAL_REPORT_TEMPLATES, id}],
        }),
        getLastArticles: builder.query({
            query: (params) => `api/services/app/VerbalReport/GetLastArticles${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result,
            providesTags: result => getProvidedTags(result.items, TAGS.LAST_ARTICLES)
        }),
        createVerbalReport: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/VerbalReport/Create',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.VERBAL_REPORTS, id: TAGS.DEFAULT}, {type: TAGS.EVENTS, id: TAGS.DEFAULT}],
        }),
        updateVerbalReport: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/VerbalReport/Update',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.VERBAL_REPORTS, id}],
        }),
        deleteVerbalReport: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/VerbalReport/Delete?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{type: TAGS.VERBAL_REPORTS, id}],
        }),
        createVerbalReportTemplate: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/VerbalReport/CreateVerbalReportTemplate',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.VERBAL_REPORT_TEMPLATES, id: TAGS.DEFAULT}],
        }),
        deleteVerbalReportTemplate: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/VerbalReport/DeleteVerbalReportTemplate?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{type: TAGS.VERBAL_REPORT_TEMPLATES, id}],
        })
    })
});

export const {
    useGetVerbalReportsQuery,
    useGetVerbalReportTemplatesQuery,
    useGetVerbalReportTemplateSelectItemsQuery,
    useGetLastArticlesQuery,
    useGetVerbalReportQuery,
    useGetVerbalReportTemplateQuery,
    useCreateVerbalReportMutation,
    useCreateVerbalReportTemplateMutation,
    useUpdateVerbalReportMutation,
    useDeleteVerbalReportMutation,
    useDeleteVerbalReportTemplateMutation
} = verbalReportApi;