import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDeleteContactMutation} from "../../../../api/ContactApi";
import snakeCase from "lodash/snakeCase";
import {isGranted} from "../../../../utils/authentication-util";
import {PERMISSION} from "../../../../config/constants";
import {Checkbox, TableCell, TableRow} from "@mui/material";
import DeleteButton from "../../../../components/interactive/DeleteButton";
import {manageTenantRouting} from "../../../../utils/tenant-utils";
import {useUnassignContactsMutation} from "../../../../api/ProjectApi";

const ContactListRow = ({element, handleOnRowClick, useRowSelect = false, handleOnClickRow, isSelected, projectId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [deleteContact, {isLoading: isDeleting}] = useDeleteContactMutation();
    const [unassignContact, {isLoading}] = useUnassignContactsMutation();
    const contact = {...element};

    const handleOnClickOverview = event => {
        event.preventDefault();
        handleOnRowClick(contact.id);
    };

    const handleOnClickUpdate = event => {
        event.preventDefault();
        navigate(manageTenantRouting(`/contact/${contact.id}`));
    };

    const handleOnClickDelete = async event => {
        event.preventDefault();
        await deleteContact(contact.id).unwrap();
    };

    const handleOnClickUnassign = async event => {
        event.preventDefault();
        await unassignContact({contactId: contact.id, id: projectId}).unwrap();
    };

    const labelId = `enhanced-table-checkbox-${contact.id}`;
    const isItemSelected = isSelected && isSelected(contact.id);

    return (<TableRow
        hover={useRowSelect}
        onClick={(event) => handleOnClickRow(event, contact.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={contact.id}
        selected={isItemSelected}
    >
        {useRowSelect && <TableCell padding="checkbox">
            <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                    'aria-labelledby': labelId,
                }}
            />
        </TableCell>}
        <td>
            <span className="block-content bold">
                {!useRowSelect &&
                    <button className="btn-link block-content bold text-left" onClick={handleOnClickOverview}>
                        {`${contact.name} ${contact.firstName}`}
                    </button>}
                {useRowSelect && <span className='block-content bold text-left'>
                    {`${contact.name} ${contact.firstName}`}
                </span>}
                <p className="text-muted mb-0 text-ellipsis">
                    {contact.enterpriseName}
                </p>
            </span>
        </td>
        <td className="pr-5">
            <span className='text-slim'>
                {t(`contact.form.type.options.${snakeCase(contact.type)}`)}
            </span>
        </td>
        <td>
            <span className='text-grey'>
               <a href={`tel:${contact.mobileNumber}`}>{contact.mobileNumber}</a>
            </span>
        </td>
        <td>
            <span className='text-grey'>
               <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </span>
        </td>
        {!useRowSelect && <td>
            <div className="btn-group-right">
                {isGranted(PERMISSION.UPDATE_CONTACT) &&
                    <button className='btn-icon-edit' onClick={handleOnClickUpdate} disabled={isDeleting}/>}

                {!projectId && <DeleteButton onClick={handleOnClickDelete} disabled={isDeleting}
                                             permission={PERMISSION.DELETE_CONTACT}/>}

                {projectId && <DeleteButton onClick={handleOnClickUnassign} disabled={isLoading}
                                            permission={PERMISSION.UPDATE_CONTACT}/>}
            </div>
        </td>}
    </TableRow>);
};

export default ContactListRow;