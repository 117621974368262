import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {SERVER_BASE_URL, TAGS} from "../config/constants";


const baseQuery = fetchBaseQuery({
    baseUrl: SERVER_BASE_URL,
    prepareHeaders: (headers) => {
        // eslint-disable-next-line no-undef
        if (!!abp.auth.getToken()) {
            // eslint-disable-next-line no-undef
            headers.set('authorization', `Bearer ${abp.auth.getToken()}`)
        }
        // eslint-disable-next-line no-undef
        headers.set('.AspNetCore.Culture', abp.utils.getCookieValue('Abp.Localization.CultureName'));
        // eslint-disable-next-line no-undef
        headers.set('Abp.TenantId', abp.multiTenancy.getTenantIdCookie());

        return headers;
    },
});

const baseQueryWithAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        // eslint-disable-next-line no-undef
        abp.auth.clearToken();
        window.location.href = '/';
    }
    return result;
};

export const api = createApi({
    baseQuery: baseQueryWithAuth,
    tagTypes: Object.values(TAGS),
    endpoints: () => ({}),
});