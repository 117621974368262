import React from 'react';
import {Popover} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TimeSheetForm from "../../pages/Timesheet/components/TimeSheetForm";

const CreateTimeSheetButton = ({taskId}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <button aria-describedby={id} className="btn-plus-create" onClick={handleClick}>
                <span className="plus-create-icon">
                    <AddIcon/>
                </span>
                Ajouter une prestation
            </button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <TimeSheetForm taskId={taskId} handler={handleClose}/>
            </Popover>

        </>
    );
};

export default CreateTimeSheetButton;