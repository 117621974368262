import React from 'react';
import Modal from 'react-modal';
import {MODAL_STYLE, PERMISSION} from "../../config/constants";
import {useGetContactQuery} from "../../api/ContactApi";
import snakeCase from "lodash/snakeCase";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import CallIcon from '@mui/icons-material/Call';
import {isGranted} from "../../utils/authentication-util";
import {manageTenantRouting} from "../../utils/tenant-utils";

const ContactModal = ({isOpen, handleOnClickClose, contactId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {data: contact} = useGetContactQuery(contactId, {skip: contactId === "0" || !contactId});

    const handleOnClick = event => {
        event.preventDefault();
        navigate(manageTenantRouting(`/contact/${contact?.id}`));
    };

    const projects = contact?.contactProjects?.map(contactProject => contactProject.project) || [];

    return (<Modal
        isOpen={isOpen}
        style={MODAL_STYLE}
        contentLabel="Contact Modal" onRequestClose={handleOnClickClose}>
        <div className="container-sm">
            <div className="row">
                <div className='col-12'>
                    <div className='container-shadow form row'>
                        <div className='col-12 form-heading '>
                            <button className='btn-close' onClick={handleOnClickClose}/>
                        </div>
                        <div className='col-12 form-profile'>
                            <h3>
                                {`${contact?.firstName} ${contact?.name}`}
                            </h3>
                            <h4>
                                {t(`contact.form.type.options.${snakeCase(contact?.type)}`)}
                            </h4>
                            <div className='container-chat'>
                                <a href={`tel:${contact?.mobileNumber}`} className='icon-chat-green'/>
                                <a href={`mailto:${contact?.email}`} className='icon-mail-green'/>
                                <a href={`tel:${contact?.phoneNumber}`} className='icon-phone-green'>
                                    <CallIcon color="inherit"/>
                                </a>
                            </div>
                        </div>
                        <div className='form-body row mt-40'>
                            <div className='col-md-6'>
                                <h6 className='text-bold green'>
                                    Entreprise
                                </h6>
                                <h6 className='text-subtitle'>
                                    {contact?.enterpriseName}
                                </h6>
                                <h6 className='text-subtitle light'>
                                    {`${(contact?.street && (contact?.postalCode || contact?.city)) ? `${contact?.street} - ` : contact?.street} ${contact?.postalCode} ${contact?.city}`}
                                </h6>
                                <h6 className='text-subtitle light'>
                                    <a href={`tel:${contact?.mobileNumber}`} className="btn-link-dark full light">
                                        {contact?.mobileNumber}
                                    </a>
                                    <a href={`mailto:${contact?.email}`} className='btn-link-dark-green full light'>
                                        {contact?.email}
                                    </a>
                                    <a href={`tel:${contact?.phoneNumber}`} className="btn-link-dark full light">
                                        {contact?.phoneNumber}
                                    </a>
                                    <a href={contact?.website} target="_blank"
                                       className='btn-link-dark-green full light'>
                                        {contact?.website}
                                    </a>
                                </h6>
                            </div>
                            <div className='col-md-6'>
                                <h6 className='text-bold green'>
                                    Fonction
                                </h6>
                                <h6 className='text-subtitle light'>
                                    {t(`contact.form.type.options.${snakeCase(contact?.type)}`)}
                                </h6>
                                <h6 className='text-bold green'>
                                    Commentaire
                                </h6>
                                <h6 className='text-subtitle light'>
                                    {contact?.comment}
                                </h6>
                            </div>
                            {projects.length > 0 && <div className='col-md-12'>
                                <h6 className='text-bold green'>
                                    Actif sur les projets
                                </h6>
                                {projects.map(project =>
                                    <a key={`contact-model-project-row-${project.id}`}
                                       href={`/project-overview/${project.id}`} className='btn-link-blue full'>
                                        {`${project.name}, ${project.city}`}
                                    </a>
                                )}
                            </div>}

                            <div className='col-12 btn-group text-center mt-40 '>
                                {isGranted(PERMISSION.UPDATE_CONTACT) &&
                                    <button className='btn-ternary' onClick={handleOnClick}>
                                        modifier
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>);
};

export default ContactModal;