import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import "./i18n/i18n";
import {BrowserRouter} from "react-router-dom";
import {store} from './app/store'
import {Provider} from "react-redux";
import {getAll} from "./api/UserConfigurationApi";
import {extend} from "./utils/utils";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr';
import dayjs from "dayjs";

dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.locale('fr');

getAll().then(response => {

    // eslint-disable-next-line no-undef
    extend(true, abp, response);

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <Suspense fallback={<div/>}>
                        <App/>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
