import {createSlice} from "@reduxjs/toolkit";
import {api} from "../../../app/SynappApi";


const slice = createSlice({
    name: 'auth',
    initialState: {account: undefined, isAuthenticated: false, tenant: undefined},
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            api.endpoints.getSession.matchFulfilled,
            (state, {payload}) => {
                state.account = payload.result.user;
                state.tenant = payload.result.tenant;
                state.isAuthenticated = payload?.result?.user != null;
            }
        )
    },
});

export default slice.reducer;

export const selectCurrentUser = state => state.auth.account;

export const selectCurrentTenant = state => state.auth.tenant;

export const selectIsAuthenticated = state => state.auth.isAuthenticated;