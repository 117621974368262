import {api} from "../app/SynappApi";
import {TAGS} from "../config/constants";
import {getProvidedTags} from "../utils/query-utils";

const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: keyword => `/api/services/app/User/GetAll?keyword=${keyword}`,
            transformResponse: (response) => response.result.items,
            providesTags: result => getProvidedTags(result, TAGS.USERS)
        }),
        getUserSelectItems: builder.query({
            query: () => "/api/services/app/User/GetAllSelectItems",
            transformResponse: (response) => response.result.items,
            providesTags: result => getProvidedTags(result, TAGS.USER_LIST_ITEMS, true)
        }),
        getUser: builder.query({
            query: (id) => `api/services/app/User/Get?id=${id}`,
            transformResponse: (response) => response.result,
            providesTags: (result, error, id) => [{type: TAGS.USERS, id}],
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/User/Create',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.USERS, id: TAGS.DEFAULT}, {type: TAGS.USER_LIST_ITEMS, id: TAGS.DEFAULT}],
        }),
        updateUser: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/User/Update',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.USERS, id}, {type: TAGS.USER_LIST_ITEMS, id}],
        }),
        deleteUser: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/User/Delete?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{type: TAGS.USERS, id}, {type: TAGS.USER_LIST_ITEMS, id}],
        }),
        getUserRoles: builder.query({
            query: () => 'api/services/app/User/GetRoles',
            transformResponse: (response) => response.result.items,
            providesTags: (result) => getProvidedTags(result, TAGS.USER_ROLES)
        }),
        updateProfile: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/User/UpdateProfile',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.USERS, id}, {type: TAGS.USER_LIST_ITEMS, id}],
        }),
        getCustomerPortalUrl: builder.query({
            query: () => 'api/services/app/User/GetCustomerPortalUrl',
            transformResponse: (response) => response.result
        }),
    })
});

export const {
    useGetUsersQuery,
    useGetUserSelectItemsQuery,
    useGetUserQuery,
    useGetUserRolesQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useUpdateProfileMutation,
    useGetCustomerPortalUrlQuery
} = userApi;