import React from 'react';
import Select from "react-select";
import classNames from "classnames";

const SelectWithLabel = ({
                             label,
                             options,
                             values,
                             selectedValue,
                             name,
                             isMulti,
                             isClearable,
                             containerClassName,
                             error,
                             ...rest
                         }) => {
    const ungroupedOptions = options.map((option) => option.options).reduce((previous, current) => previous.concat(current), []).filter(option => option);
    const _options = ungroupedOptions.length > 0 ? ungroupedOptions : options;

    let value = selectedValue;

    if (name)
        value = isMulti ? _options.filter(opt => values[name].includes(opt.value)) : _options.find(opt => opt.value === values[name]);

    const styles = {
        multiValueRemove: (base, state) => {
            return !isClearable && isMulti ? {...base, display: 'none'} : base;
        }
    };


    return (<div className={classNames('container-text', {"text-danger": error}, containerClassName)}
                 onClick={rest.onClick}>
        {label && <label className='label-component'>
            {label}
        </label>}
        <Select className={classNames("container-select w-100", {"is-invalid": error})}
                value={value} name={name} options={options}
                isMulti={isMulti} isClearable={isClearable} backspaceRemovesValue={isClearable}
                styles={styles} {...rest}/>
        {error && <div className="invalid-feedback">{error}</div>}
    </div>);
};

export default SelectWithLabel;