import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import {localesFr, localesNl} from "./locales"
import LanguageDetector from "i18next-browser-languagedetector"
import postProcessor from 'i18next-sprintf-postprocessor';

i18n
    .use(LanguageDetector)
    .use(postProcessor)
    .use(initReactI18next)
    .init({
        fallbackLng: "fr",
        whitelist: ["fr", "nl", "en"],
        interpolation: {escapeValue: false},
        resources: {
            fr: {translation: localesFr},
            nl: {translation: localesNl}
        },
        react: {
            useSuspense: true
        },
        overloadTranslationOptionHandler: postProcessor.overloadTranslationOptionHandler
    })

export default i18n;