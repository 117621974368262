import {getBadgeLabel} from "../utils/badge-utils";
import {getTypeLabel} from "../utils/task-utils";
import {getContactTypeLabel} from "../utils/contact-utils";
import {getEventStatusLabel, getEventTypeLabel} from "../utils/event-utils";
import {getPersonStatusLabel} from "../utils/verbalReport-utils";
import {getPhaseLabel} from "../utils/project-utils";

export const SERVER_BASE_URL = window.Configuration.SERVER_BASE_URL;
export const STRIPE_PRICING_TABLE_ID = window.Configuration.STRIPE_PRICING_TABLE_ID;
export const STRIPE_PUBLISHABLE_KEY = window.Configuration.STRIPE_PUBLISHABLE_KEY;

export const AUTH_TOKEN_KEY = "synapp-authentication-token";

export const AUTH_TENANCY_NAME = "synapp-authentication-tenancy-name";

export const PERMISSION = {
    PAGE_TENANTS: "Pages.Tenants",
    PAGE_USERS: "Pages.Users",
    UPDATE_USER: "Update.User",
    CREATE_USER: "Create.User",
    DELETE_USER: "Delete.User",
    PAGE_USERS_ACTIVATION: "Pages.Users.Activation",
    PAGE_ROLES: "Pages.Roles",
    UPDATE_ROLE: "Update.Role",
    CREATE_ROLE: "Create.Role",
    DELETE_ROLE: "Delete.Role",
    PAGE_CONTACTS: "Pages.Contacts",
    UPDATE_CONTACT: "Update.Contact",
    CREATE_CONTACT: "Create.Contact",
    DELETE_CONTACT: "Delete.Contact",
    PAGE_PROJECTS: "Pages.Projects",
    UPDATE_PROJECT: "Update.Project",
    CREATE_PROJECT: "Create.Project",
    DELETE_PROJECT: "Delete.Project",
    PAGE_TASKS: "Pages.Tasks",
    UPDATE_TASK: "Update.Task",
    UPDATE_TASK_STATUS: "Update.Task.Status",
    CREATE_TASK: "Create.Task",
    DELETE_TASK: "Delete.Task",
    PAGE_EVENTS: "Pages.Events",
    UPDATE_EVENT: "Update.Event",
    CREATE_EVENT: "Create.Event",
    DELETE_EVENT: "Delete.Event",
    UPDATE_EVENT_STATUS: "Update.Event.Status",
    PAGE_TIME_SHEETS: "Pages.TimeSheets",
    UPDATE_TIME_SHEET: "Update.TimeSheet",
    CREATE_TIME_SHEET: "Create.TimeSheet",
    DELETE_TIME_SHEET: "Delete.TimeSheet",
    PAGE_INVOICES: "Pages.Invoices",
    PAGE_TIMELINE: "Pages.Timeline",
    PAGE_USER_SETTINGS: "Pages.User.Settings",
    PAGE_USER_SETTINGS_UPDATING: "Pages.User.Settings.Updating",
    PAGE_USER_SETTINGS_ESTIMATES: "Pages.User.Settings.Estimates",
    EXPORT_DATA: "Export.Data",
    PAGE_VERBAL_REPORTS: "Pages.VerbalReports",
    CREATE_VERBAL_REPORT: "Create.VerbalReport",
    UPDATE_VERBAL_REPORT: "Update.VerbalReport",
    DELETE_VERBAL_REPORT: "Delete.VerbalReport",
    GENERAL_DOCUMENT: "Generate.Document",
    PAGE_CUSTOMER_PORTAL: "Pages.Customer.Portal",
    PAGE_USER_SETTINGS_REPORT_TEMPLATES: "Pages.User.Settings.Report.Templates"
};

export const ENCRYPTED_AUTH_TOKEN_NAME = 'enc_auth_token';

export const AVAILABILITY_STATE = {
    AVAILABLE: 1, INACTIVE: 2, NOT_FOUND: 3
};

export const TAGS = {
    DEFAULT: 'LIST',
    TENANTS: "TENANTS",
    CONTACTS: "CONTACTS",
    CONTACT_LIST_ITEMS: "CONTACT_LIST_ITEMS",
    CONTACT_ENTERPRISE_LIST_ITEMS: "CONTACT_ENTERPRISE_LIST_ITEMS",
    PROJECTS: "PROJECTS",
    PROJECT_LIST_ITEMS: "PROJECT_LIST_ITEMS",
    TASKS: "TASKS",
    TASK_LIST_ITEMS: "TASK_LIST_ITEMS",
    USERS: "USERS",
    USER_LIST_ITEMS: "USER_LIST_ITEMS",
    ROLES: "ROLES",
    PERMISSIONS: "PERMISSIONS",
    TIME_SHEETS: "TIME_SHEETS",
    USER_ROLES: "USER_ROLES",
    EVENTS: "EVENTS",
    PROJECT_CATEGORIES: "PROJECT_CATEGORIES",
    TIMELINE: "TIMELINE",
    VERBAL_REPORTS: "VERBAL_REPORTS",
    VERBAL_REPORT_TEMPLATES: "VERBAL_REPORT_TEMPLATES",
    VERBAL_REPORT_TEMPLATES_LIST_ITEMS: "VERBAL_REPORT_TEMPLATES_LIST_ITEMS",
    LAST_ARTICLES: "LAST_ARTICLES"
};

export const CONTACT_GENDER = {
    MALE: 0, FEMALE: 1, MALE_FEMALE: 2, UNKNOWN: 3
};

export const CONTACT_TYPE = {
    PROJECT_OWNER: 0,
    ARCHITECT: 1,
    SURVEYOR: 2,
    PEB: 3,
    ENGINEER: 4,
    CONTRACTOR: 5,
    DRAWER: 6,
    ENERGY_AUDITOR: 7,
    SAFETY_COORDINATOR: 8,
    SPECIALIST_TECHNIQUES: 9,
    BIM_MANAGER: 10,
    ADMINISTRATION: 11,
    PROMOTER: 12,
    NOTARY: 13,
    SOIL_TESTING: 14,
    UNKNOWN: 15
};

export const STATUS = {
    TO_DO: 0, IN_PROGRESS: 1, URGENT: 2, WAITING: 3, DONE: 4
};

export const TASK_TYPE = {
    ON_SITE_READING: 0,
    DRAWING: 1,
    DOCUMENTS: 2,
    ADJUDICATION: 3,
    MODELING: 4,
    IMAGERY: 5,
    MODIFICATIONS: 6,
    QUANTITY_SURVEY: 7,
    SPECIFICATIONS: 8,
    DETAILS: 9,
    AS_BUILD: 10,
    OTHER: 11,
    NOT_DEFINED: 12
};

export const PROJECT_STATUS = {
    IN_PROGRESS: 0, ARCHIVED: 1
};

export const PROJECT_CATEGORY_TYPE = {
    NEW_CONSTRUCTION: 0, RENOVATION: 1, EXPANSION: 2, REGULARIZATION: 3, OTHER: 4
};

export const PROJECT_CATEGORY_SUBTYPE = {
    SMALL_HOUSE: 0,
    MEDIUM_SIZED_HOUSE: 1,
    LARGE_HOUSE: 2,
    SMALL_BUILDING: 3,
    MEDIUM_SIZED_BUILDING: 4,
    LARGE_BUILDING: 5,
    SIMPLE_INDUSTRIAL_BUILDING: 6,
    COMPLEX_INDUSTRIAL_BUILDING: 7,
    AGRICULTURAL_HANGAR: 8,
    OTHER: 9,
    SMALL_SCALE_HOUSING_ESTATE: 10,
    LARGE_SCALE_HOUSING_ESTATE: 11
}

export const TIMELINE_TYPE = {
    TASK: 0, CONTACT: 1, EVENT: 2, PROJECT: 3
};

export const TIMELINE_SUBTYPE = {
    CREATION: 0, MODIFICATION: 1, DELETION: 2
};

export const EVENT_STATUS = {
    TO_COME: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2
};

export const EVENT_TYPE = {
    UNKNOWN: 0,
    SITE_SURVEY: 1,
    CLIENT_MEETING: 2,
    TEAM_MEETING: 3,
    ADMINISTRATION_MEETING: 4,
    CONSTRUCTION_MEETING: 5,
    PROVISIONAL_ACCEPTANCE: 6,
    FINAL_ACCEPTANCE: 7
};

export const PERSON_STATUS = {
    ATTENDING: 0,
    LATE: 1,
    MISSING: 2,
    EXCUSED: 3,
    NOT_CONCERNED: 4
};

export const VERBAL_REPORT_STATUS = {
    TO_BE_WRITTEN: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2,
    PUBLISHED: 3
};

export const MODAL_STYLE = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 0,
        background: 'none',
        overflow: 'visible'
    }, overlay: {
        zIndex: 9999
    }
};

export const STATUS_OPTIONS = Object.values(STATUS).map(value => ({value, label: getBadgeLabel(value)}));

export const TYPE_OPTIONS = Object.values(TASK_TYPE).map(value => ({value, label: getTypeLabel(value)}));

export const EVENT_STATUS_OPTIONS = Object.values(EVENT_STATUS).map(value => ({
    value,
    label: getEventStatusLabel(value)
}));

export const EVENT_TYPE_OPTIONS = Object.values(EVENT_TYPE).map(value => ({
    value,
    label: getEventTypeLabel(value)
}));

export const CONTACT_TYPE_OPTIONS = Object.values(CONTACT_TYPE).filter(v => v !== CONTACT_TYPE.UNKNOWN).map(value => ({
    value,
    label: getContactTypeLabel(value)
}));

export const PERSON_STATUS_OPTIONS = Object.values(PERSON_STATUS).map(value => ({
    value,
    label: getPersonStatusLabel(value)
}));

export const PAGE_SIZE = 15;

export const DEFAULT_CONTACT = {
    id: "0",
    name: "",
    firstName: "",
    enterpriseName: "",
    type: CONTACT_TYPE.UNKNOWN,
    phoneNumber: "",
    mobileNumber: "",
    faxNumber: "",
    email: "",
    street: "",
    houseNumber: "",
    city: "",
    country: "",
    comment: "",
    title: CONTACT_GENDER.UNKNOWN,
    postalCode: ""
};

export const DEFAULT_EVENT = {
    id: "0",
    status: EVENT_STATUS.TO_COME,
    type: EVENT_TYPE.UNKNOWN,
    name: undefined,
    description: undefined,
    startDate: undefined,
    endDate: undefined,
    userIds: [],
    contactIds: []
};

export const CONTACT_TITLE_OPTIONS = [
    {value: CONTACT_GENDER.MALE, label: 'Monsieur'},
    {value: CONTACT_GENDER.FEMALE, label: 'Madame'},
    {value: CONTACT_GENDER.MALE_FEMALE, label: 'Monsieur et Madame'}
];

export const PROJECT_PHASE = {
    STARTING_UP: 0,
    EXISTING_SITUATION: 1,
    PRELIMINARY_DESIGN: 2,
    PLANNING_PERMISSION: 3,
    EXECUTION: 4,
    SALE: 5
};

export const PROJECT_PHASE_OPTIONS = Object.values(PROJECT_PHASE).map(value => ({
    value,
    label: getPhaseLabel(value)
}));
