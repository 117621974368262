import React, {useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Breadcrumb from "../../../components/layout/Main/Breadcrumb";
import SearchBar from "../../../components/interactive/SearchBar";
import Title from "../../../components/layout/Main/Title";
import ExportButton from "../../../components/interactive/ExportButton";
import CustomTable from "../../../components/table/CustomTable";
import {useGetContactEnterprisesSelectItemsQuery, useGetContactsQuery} from "../../../api/ContactApi";
import ContactListRow from "./components/ContactListRow";
import ContactModal from "../../../components/Contact/ContactModal";
import {isGranted} from "../../../utils/authentication-util";
import {CONTACT_TYPE_OPTIONS, PERMISSION} from "../../../config/constants";
import ContactFiltersList from "./components/ContactFiltersList";
import {useDispatch} from "react-redux";
import {
    setAssignContactModalIsOpen,
    setContactModalIsOpen,
    setSelectedProjectId
} from "../../../components/Contact/contactSlice";
import {manageTenantRouting} from "../../../utils/tenant-utils";

const Contact = ({
                     projectId,
                     excludedProjectId,
                     contentElement: ContentElement,
                     useRowSelect = false,
                     handleOnClickRow,
                     isSelected
                 }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState('');
    const [selectedId, setSelectedId] = useState();
    const [enterpriseName, setEnterpriseName] = useState();
    const [type, setType] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const {data: contactEnterprises} = useGetContactEnterprisesSelectItemsQuery();

    const handleOnClick = event => {
        event.preventDefault();
        navigate(manageTenantRouting("/contact/0"));
    };

    const handleOnClickCreate = event => {
        event.preventDefault();
        dispatch(setContactModalIsOpen(true));
        dispatch(setSelectedProjectId(projectId));
    };

    const handleOnRowClick = rowId => {
        setSelectedId(rowId);
        setIsOpen(true);
    };

    const handleOnClickClose = event => {
        event.preventDefault();
        setIsOpen(false);
    };

    const handleOnChangeEnterpriseName = selectedValue => {
        setEnterpriseName(selectedValue?.value);
    };

    const handleOnChangeType = selectedValue => {
        setType(selectedValue?.value);
    };

    const handleOnClickAssign = event => {
        event.preventDefault();
        dispatch(setAssignContactModalIsOpen(true));
        dispatch(setSelectedProjectId(projectId));
    };

    const enterpriseNameFilters = contactEnterprises?.map(({value, text}) => ({value: value, label: text})) || [];

    const selectedEnterpriseName = enterpriseNameFilters?.find(filter => filter.value === enterpriseName);
    const selectedType = CONTACT_TYPE_OPTIONS.find(filter => filter.value === type);

    const columns = useMemo(() => {
        const _columns = [
            {name: "Contact", className: "w-20", sorting: "EnterpriseName, Name"},
            {name: "Profession", className: "w-20", sorting: "Type"},
            {name: "Numéro de GSM", className: "w-15", sorting: "MobileNumber"},
            {name: "Email", className: "w-15", sorting: "Email"}
        ];

        if (useRowSelect) {
            _columns.unshift({name: "", className: "w-5", sorting: ""});
        } else {
            _columns.push({name: "", className: "w-5", sorting: ""})
        }

        return _columns;
    }, [useRowSelect]);

    const contactFiltersProps = {
        enterpriseNameFilters,
        selectedEnterpriseName,
        handleOnChangeEnterpriseName,
        selectedType,
        handleOnChangeType,
        useRowSelect
    };

    const isContactListPage = !projectId && !useRowSelect;

    return (<>
        <ContactModal isOpen={isOpen} handleOnClickClose={handleOnClickClose} contactId={selectedId}/>
        {isContactListPage && <>
            <Title>Liste Contacts</Title>
            <Breadcrumb>Contacts</Breadcrumb>
            <Breadcrumb>Liste Contacts</Breadcrumb>
        </>}
        <ContentElement>
            <div className='section-filters w-100 row'>
                {(projectId && isGranted(PERMISSION.CREATE_CONTACT)) && <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-12 text-right mb-3'>
                            <div className="container-buttons">
                                <button className='btn-primary btn-min-w' onClick={handleOnClickCreate}>
                                    {t('contact.create_button')}
                                </button>
                                <button className='btn-secondary btn-min-w' onClick={handleOnClickAssign}>
                                    {t('contact.assign_button')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='col-md-12 mb-3'>
                    <div className='row'>
                        {!isContactListPage && <div className='col-md-7'>
                            <ContactFiltersList {...contactFiltersProps}/>
                        </div>}
                        <div className={!isContactListPage ? 'col-md-5' : 'col-md-4'}>
                            <SearchBar onChange={setKeyword} placeholder={t('contact.search_placeholder')}/>
                        </div>
                        {isContactListPage && <div className='col-md-8 text-right'>
                            <div className="container-buttons" id="container-buttons-contact">
                                {isGranted(PERMISSION.CREATE_CONTACT) &&
                                    <button className='btn-primary btn-min-w' onClick={handleOnClick}>
                                        {t('contact.create_button')}
                                    </button>}
                                <ExportButton entityName="Contact"/>
                            </div>
                        </div>}
                    </div>
                </div>
                {isContactListPage && <div className='col-md-12'>
                    <ContactFiltersList {...contactFiltersProps}/>
                </div>}
            </div>
            <CustomTable columns={columns} search={{keyword, enterpriseName, type, projectId, excludedProjectId}}
                         useFetchElement={useGetContactsQuery}
                         ListRowElement={(props) => <ContactListRow {...props} useRowSelect={useRowSelect}
                                                                    handleOnClickRow={handleOnClickRow}
                                                                    projectId={projectId}
                                                                    isSelected={isSelected}/>}
                         handleOnRowClick={handleOnRowClick}
                         defaultSorting="EnterpriseName, Name"
                         entityName="Contact"/>
        </ContentElement>
    </>);
};

Contact.defaultProps = {
    contentElement: ({children}) => <section className='container-section row'>{children}</section>
};

export default Contact;