import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {createPortal} from "react-dom";
import {manageTenantRouting} from "../../../utils/tenant-utils";

const Breadcrumb = ({breadcrumbOutletId = "default-breadcrumb", link, children}) => {

    const [breadcrumbOutlet, setBreadcrumbOutlet] = useState();
    const {pathname} = useLocation();

    useEffect(() => {
        const outletElement = document.getElementById(breadcrumbOutletId);
        if (outletElement) {
            setBreadcrumbOutlet(outletElement);
        }
    }, [breadcrumbOutletId]);

    if (!breadcrumbOutlet) return null;

    const to = !link ? pathname : manageTenantRouting(link);

    return createPortal(<li>
        <Link to={to}>
            {children}
        </Link>
    </li>, breadcrumbOutlet);
};

export default Breadcrumb;