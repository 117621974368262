import {TASK_TYPE} from "../config/constants";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import {getPhaseLabel} from "./project-utils";

export const getTypeLabel = type => {
    switch (type) {
        case TASK_TYPE.ON_SITE_READING:
            return "Relevé sur place";
        case TASK_TYPE.DRAWING:
            return "Dessin";
        case TASK_TYPE.DOCUMENTS:
            return "Documents";
        case TASK_TYPE.ADJUDICATION:
            return "Adjudication";
        case TASK_TYPE.MODELING:
            return "Modélisation";
        case TASK_TYPE.IMAGERY:
            return "Imagerie";
        case TASK_TYPE.MODIFICATIONS:
            return "Modifications";
        case TASK_TYPE.SPECIFICATIONS:
            return "Métrés";
        case TASK_TYPE.QUANTITY_SURVEY:
            return "Cahier des charges";
        case TASK_TYPE.DETAILS:
            return "Détails";
        case TASK_TYPE.AS_BUILD:
            return "As build";
        case TASK_TYPE.OTHER:
            return "Autre";
        case TASK_TYPE.NOT_DEFINED:
            return "Non défini";
        default:
            return "Relevé sur place";
    }
};

export const mapTasksToOptions = tasks => {
    const groupedTasks = groupBy(tasks, c => c.projectId);
    return keys(groupedTasks).map(group => ({
        label: groupedTasks[group][0].projectName,
        options: groupedTasks[group].map(mapTaskToOption)
    }));
};

export const mapTaskToOption = ({phase, type, value}) => ({
    label: `${getPhaseLabel(phase)} - ${getTypeLabel(type)}`,
    value: parseInt(value)
});