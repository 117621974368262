import {SERVER_BASE_URL} from "../config/constants";

export const getAll = async () => {
    let response = "";
    try {
        response = await fetch(SERVER_BASE_URL + '/AbpUserConfiguration/GetAll', {
            headers: new Headers({
                // eslint-disable-next-line no-undef
                'Authorization': `Bearer ${abp.auth.getToken()}`
            }),
        })
            .then(response => response.json())
            .then(response => {
                return response.result;
            });
    } catch (error) {
        console.log(error);
    }
    return response;
};