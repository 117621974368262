import {PROJECT_PHASE, PROJECT_STATUS} from "../config/constants";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import {getContactTypeLabel} from "./contact-utils";

export const mapContactsToOptions = contacts => {
    const groupedContacts = groupBy(contacts, c => c.type);
    return keys(groupedContacts).map(group => ({
        label: getContactTypeLabel(parseInt(group)),
        options: groupedContacts[group].map(mapContactToOption)
    }));
};

export const mapContactToOption = contact => ({
    label: contact.name || contact.firstName ? `${contact.name} ${contact.firstName}` : contact.enterpriseName,
    value: contact.id
});

export const getStatusClass = status => {
    switch (status) {
        case PROJECT_STATUS.IN_PROGRESS:
            return "message-success";
        case PROJECT_STATUS.ARCHIVED:
            return "message-error";
        default:
            return "message-error";
    }
};

export const getStatusLabel = status => {
    switch (status) {
        case PROJECT_STATUS.IN_PROGRESS:
            return "En cours";
        case PROJECT_STATUS.ARCHIVED:
            return "Archivé";
        default:
            return "En cours";
    }
};

export const getBulletClass = status => {
    switch (status) {
        case PROJECT_STATUS.IN_PROGRESS:
            return "icon-bullet-check";
        case PROJECT_STATUS.ARCHIVED:
            return "icon-bullet-stop";
        default:
            return "icon-bullet-stop";
    }
};

export const mapProjectContactIds = project => {
    const {contactIds, ...rest} = {...project};
    const clientContactsProjectId = project?.contactProjects?.filter(contactProject => contactProject.isClient).map(contactProject => contactProject.contact.id)[0];
    const contactsProjectIds = project?.contactProjects?.filter(contactProject => !contactProject.isClient).map(contactProject => contactProject.contact.id);
    return {...rest, contactIds: [...contactsProjectIds], clientId: clientContactsProjectId};
};

export const getPhaseLabel = phase => {
    switch (phase) {
        case PROJECT_PHASE.STARTING_UP:
            return "Mise en route";
        case PROJECT_PHASE.EXISTING_SITUATION:
            return "Situation existante";
        case PROJECT_PHASE.PRELIMINARY_DESIGN:
            return "Esquisse / Avant-projet";
        case PROJECT_PHASE.PLANNING_PERMISSION:
            return "Permis d'urbanisme";
        case PROJECT_PHASE.EXECUTION:
            return "Exécution";
        case PROJECT_PHASE.SALE:
            return "Vente";
        default:
            return "Non défini";
    }
};