import {AUTH_TENANCY_NAME, AVAILABILITY_STATE} from "../config/constants";

// eslint-disable-next-line no-undef
export const TENANCY_NAME = abp.utils.getCookieValue(AUTH_TENANCY_NAME);

export const manageTenantRouting = path => {
    if (!TENANCY_NAME) return path;

    return `/${TENANCY_NAME}${path}`;
};

export const manageTenant = async (isTenantAvailable, tenancyName) => {
    if (!tenancyName) {
        // eslint-disable-next-line no-undef
        abp.multiTenancy.setTenantIdCookie(undefined);
        // eslint-disable-next-line no-undef
        abp.utils.deleteCookie(AUTH_TENANCY_NAME, abp.appPath);
    } else {
        const {result} = await isTenantAvailable(tenancyName).unwrap();
        if (result.state === AVAILABILITY_STATE.AVAILABLE) {
            // eslint-disable-next-line no-undef
            abp.multiTenancy.setTenantIdCookie(result.tenantId);
            // eslint-disable-next-line no-undef
            abp.utils.setCookieValue(AUTH_TENANCY_NAME, tenancyName, new Date(new Date().getTime() + 5 * 365 * 86400000), //5 years
                // eslint-disable-next-line no-undef
                abp.appPath, // eslint-disable-next-line no-undef
                abp.domain);
        }
    }
};