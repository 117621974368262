import {api} from "./SynappApi";
import {configureStore} from "@reduxjs/toolkit";
import authReducer from "../pages/Authorization/Login/authenticationSlice";
import taskManagementReducer from "../components/Task/taskSlice";
import contactManagementReducer from "../components/Contact/contactSlice";
import eventManagementReducer from "../components/Event/eventSlice";

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        taskManagement: taskManagementReducer,
        contactManagement: contactManagementReducer,
        eventManagement: eventManagementReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware)
});