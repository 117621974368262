import {api} from "../app/SynappApi";
import {TAGS} from "../config/constants";
import {getProvidedTags, objectToQueryStrings} from "../utils/query-utils";

const timeSheetApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTimeSheets: builder.query({
            query: (params) => `/api/services/app/TimeSheet/GetAll${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result,
            providesTags: result => getProvidedTags(result.items, TAGS.TIME_SHEETS)
        }),
        getTimeSheet: builder.query({
            query: (id) => `api/services/app/TimeSheet/Get?id=${id}`,
            transformResponse: (response) => response.result,
            providesTags: (result, error, id) => [{type: TAGS.TIME_SHEETS, id}],
        }),
        createTimeSheet: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/TimeSheet/Create',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.TIME_SHEETS, id: TAGS.DEFAULT}],
        }),
        updateTimeSheet: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/TimeSheet/Update',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.TIME_SHEETS, id}],
        }),
        deleteTimeSheet: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/TimeSheet/Delete?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{type: TAGS.TIME_SHEETS, id}],
        })
    })
});

export const {
    useGetTimeSheetsQuery,
    useGetTimeSheetQuery,
    useCreateTimeSheetMutation,
    useUpdateTimeSheetMutation,
    useDeleteTimeSheetMutation
} = timeSheetApi;