import {api} from "../app/SynappApi";
import {TAGS} from "../config/constants";
import dayjs from "dayjs";
import {getProvidedTags, objectToQueryStrings} from "../utils/query-utils";

const projectApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query({
            query: params => `/api/services/app/Project/GetAll${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result,
            providesTags: (result) => getProvidedTags(result.items, TAGS.PROJECTS)
        }),
        getProject: builder.query({
            query: (id) => `api/services/app/Project/Get?id=${id}`,
            transformResponse: (response) => response.result,
            providesTags: (result, error, id) => [{type: TAGS.PROJECTS, id}],
        }),
        getProjectSelectItems: builder.query({
            query: () => 'api/services/app/Project/GetAllSelectItems',
            transformResponse: (response) => response.result.items,
            providesTags: (result) => getProvidedTags(result, TAGS.PROJECT_LIST_ITEMS, true)
        }),
        getProjectContacts: builder.query({
            query: ({
                        projectId,
                        keyword
                    }) => `/api/services/app/Contact/GetAll?projectId=${projectId}&keyword=${keyword}`,
            transformResponse: (response) => response.result.items,
            providesTags: result => getProvidedTags(result, TAGS.CONTACTS)
        }),
        getProjectTasks: builder.query({
            query: ({
                        projectId,
                        status,
                        type,
                        keyword,
                        deadline
                    }) => `/api/services/app/Task/GetAll?projectId=${projectId}&keyword=${keyword}${status !== undefined ? `&status=${status}` : ''}${type !== undefined ? `&type=${type}` : ''}${deadline !== undefined ? `&deadline=${deadline.toJSON()}` : ''}`,
            transformResponse: (response) => response.result.items,
            providesTags: result => getProvidedTags(result, TAGS.TASKS)
        }),
        createProject: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Project/Create',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.PROJECTS, id: TAGS.DEFAULT}, {
                type: TAGS.PROJECT_LIST_ITEMS,
                id: TAGS.DEFAULT
            }],
        }),
        updateProject: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Project/Update',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.PROJECTS, id}, {type: TAGS.PROJECT_LIST_ITEMS, id}],
        }),
        deleteProject: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/Project/Delete?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{type: TAGS.PROJECTS, id}, {type: TAGS.PROJECT_LIST_ITEMS, id}],
        }),
        getDashboardProjects: builder.query({
            query: () => '/api/services/app/Project/GetDashboardProjects',
            transformResponse: (response) => response.result.items,
            providesTags: (result) => getProvidedTags(result, TAGS.PROJECTS)
        }),
        exportXML: builder.mutation({
            query(body) {
                return {
                    url: 'api/services/app/Project/ExportXml',
                    method: "POST",
                    body,
                    responseHandler: async (response) => {
                        const url = URL.createObjectURL(await response.blob());
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = `project_${body.id}${dayjs().format("YYYY-MM-DD")}.xml`;
                        link.click();
                        window.URL.revokeObjectURL(url);
                    },
                    cache: "no-cache",
                };
            }
        }),
        getProjectCategories: builder.query({
            query: (params) => `api/services/app/Project/GetProjectCategories${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result.items,
            providesTags: result => getProvidedTags(result, TAGS.PROJECT_CATEGORIES)
        }),
        createProjectCategory: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Project/CreateProjectCategory',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.PROJECT_CATEGORIES, id: TAGS.DEFAULT}],
        }),
        deleteProjectCategory: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/Project/DeleteProjectCategory?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, arg) => [{type: TAGS.PROJECT_CATEGORIES, id: arg.id}],
        }),
        updateProjectStatus: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Project/UpdateStatus',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.PROJECTS, id}],
        }),
        assignContacts: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Project/AssignContacts',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.PROJECTS, id}, {
                type: TAGS.CONTACTS,
                id: TAGS.DEFAULT
            }]
        }),
        unassignContacts: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Project/UnassignContact',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.PROJECTS, id}, {
                type: TAGS.CONTACTS,
                id: TAGS.DEFAULT
            }]
        }),
        updateProjectPhase: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Project/UpdatePhase',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.PROJECTS, id}],
        }),
    })
});

export const {
    useGetProjectsQuery,
    useGetProjectSelectItemsQuery,
    useGetProjectQuery,
    useGetProjectContactsQuery,
    useGetProjectTasksQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
    useGetDashboardProjectsQuery,
    useExportXMLMutation,
    useGetProjectCategoriesQuery,
    useCreateProjectCategoryMutation,
    useDeleteProjectCategoryMutation,
    useUpdateProjectStatusMutation,
    useAssignContactsMutation,
    useUnassignContactsMutation,
    useUpdateProjectPhaseMutation
} = projectApi;