import {api} from "../app/SynappApi";

const authenticationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'api/TokenAuth/Authenticate',
                method: 'POST',
                body: credentials,
            }),
        }),
        register: builder.mutation({
            query: (credentials) => ({
                url: 'api/services/app/Account/Register',
                method: 'POST',
                body: credentials,
            }),
        }),
        isTenantAvailable: builder.mutation({
            query: (tenancyName) => ({
                url: 'api/services/app/Account/IsTenantAvailable',
                method: 'POST',
                body: {tenancyName},
            }),
        }),
        IsEmailAvailable: builder.mutation({
            query: (emailAddress) => ({
                url: 'api/services/app/Account/IsEmailAvailable',
                method: 'POST',
                body: {emailAddress},
            }),
        }),
        getSession: builder.mutation({
            query: () => ({
                url: 'api/services/app/Session/GetCurrentLoginInformations',
                method: 'GET'
            }),
        }),
        sendResetPassword: builder.mutation({
            query: (emailAddress) => ({
                url: '/api/services/app/Account/SendPasswordResetCode',
                method: 'POST',
                body: emailAddress
            })
        }),
        resetPassword: builder.mutation({
            query: (resetPasswordInput) => ({
                url: '/api/services/app/Account/ResetPassword',
                method: 'POST',
                body: resetPasswordInput
            })
        })
    }),
    overrideExisting: false,
})

export const {
    useLoginMutation,
    useRegisterMutation,
    useIsTenantAvailableMutation,
    useIsEmailAvailableMutation,
    useGetSessionMutation,
    useSendResetPasswordMutation,
    useResetPasswordMutation
} = authenticationApi;