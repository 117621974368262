import {useSelector} from "react-redux";
import {
    selectModalIsOpen,
    selectSelectedProjectId
} from "../components/Event/eventSlice";
import {useMemo} from "react";

export const useEventManagement = () => {
    const modalIsOpen = useSelector(selectModalIsOpen);
    const selectedProjectId = useSelector(selectSelectedProjectId);

    return useMemo(() => ({
        modalIsOpen,
        selectedProjectId
    }), [modalIsOpen, selectedProjectId]);
};