import React, {useEffect, useMemo} from 'react';
import {PERMISSION, PROJECT_PHASE_OPTIONS, STATUS, TYPE_OPTIONS} from "../../config/constants";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import useFormValidation from "../../hooks/useFormValidation";
import {useGetProjectSelectItemsQuery} from "../../api/ProjectApi";
import {useGetUserSelectItemsQuery} from "../../api/UserApi";
import {useCreateTaskMutation, useGetTaskQuery, useUpdateTaskMutation} from "../../api/TaskApi";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import SelectWithLabel from "../interactive/SelectWithLabel";
import {Box, Tab, Tabs} from "@mui/material";
import TabPanel from "./TabPanel";
import {toUTCString} from "../../utils/date-utils";
import {useDispatch} from "react-redux";
import {setSelectedProjectId, setSelectedTaskId} from "./taskSlice";
import CustomTable from "../table/CustomTable";
import {useGetTimeSheetsQuery} from "../../api/TimeSheetApi";
import TimeSheetSimplifiedListRow from "../../pages/Timesheet/components/TimeSheetSimplifiedListRow";
import CreateTimeSheetButton from "./CreateTimeSheetButton";
import {isGranted} from "../../utils/authentication-util";

const defaultTask = {
    id: "0",
    name: "",
    phase: undefined,
    type: undefined,
    status: STATUS.TO_DO,
    deadline: undefined,
    estimatedTime: undefined,
    forecastDate: undefined,
    isDefault: true,
    projectId: undefined,
    userIds: []
};

const TaskModal = ({isOpen, onRequestClose, id = "0", projectId}) => {
        const {t} = useTranslation();
        const dispatch = useDispatch();
        const [createTask, {isLoading: isCreating}] = useCreateTaskMutation();
        const [updateTask, {isLoading: isUpdating}] = useUpdateTaskMutation();
        const {data: projects} = useGetProjectSelectItemsQuery('', {skip: (!isOpen || !!projectId)});
        const {data: users} = useGetUserSelectItemsQuery('', {skip: !isOpen});
        const [value, setValue] = React.useState(0);
        const {data: task} = useGetTaskQuery(id, {skip: id === "0"});

        useEffect(() => {
            return () => {
                dispatch(setSelectedTaskId("0"));
                dispatch(setSelectedProjectId(undefined));
            };
        }, []);

        useEffect(() => {
            if (!isOpen) setValues({...defaultTask});
            else if (!!projectId) setValues({...defaultTask, projectId: parseInt(projectId)});
        }, [isOpen]);

        const validateForm = values => {
            let errors = {};
            if (!values.projectId) {
                errors.projectId = "Veuillez sélectionner un projet";
            }
            return errors;
        };

        const handleSubmit = async values => {
            try {
                if (values.id === "0") {
                    await createTask(values).unwrap();
                } else {
                    await updateTask(values).unwrap();
                }
                setValues(defaultTask);
                dispatch(setSelectedTaskId("0"));
                dispatch(setSelectedProjectId(undefined));
                onRequestClose();
            } catch (error) {
                console.error(error);
                toast.error(t('task.form.error.task_not_created'));
            }
        };

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const {
            values, errors, handleOnSelectChange, handleOnDateChange, handleOnSubmit, setValues
        } = useFormValidation({...defaultTask, projectId}, validateForm, handleSubmit);


        useEffect(() => {
            if (id !== "0" && task) {
                let _task = {...task};
                if (task.deadline) {
                    _task = {..._task, deadline: toUTCString(task.deadline)};
                }

                if (task.forecastDate) {
                    _task = {..._task, forecastDate: toUTCString(task.forecastDate)};
                }
                setValues(_task);
                setValue(0);
            }
        }, [id, task, values.id]);

        const projectOptions = projects?.map(({value, text}) => ({value: parseInt(value), label: text})) || [];
        const userOptions = users?.map(({value, text}) => ({value: parseInt(value), label: text})) || [];


        const columns = useMemo(() => [
            {name: "#", className: "w-15", sorting: ""},
            {name: "Utilisateur", className: "", sorting: ""},
            {name: "Temps passé", className: "", sorting: "TimeSpent"},
            {name: "Date", className: "", sorting: "TimeSpentDate"},
            {name: "", className: "w-5", sorting: ""}
        ], []);

        const containerElement = ({children}) => <>{children}</>;

        const createButtonElement = isGranted(PERMISSION.CREATE_TIME_SHEET) ? CreateTimeSheetButton : null;

        return (<Modal
                isOpen={isOpen} className="modal modal-task" overlayClassName="modal-overlay"
                contentLabel="Task Modal" onRequestClose={onRequestClose}>
                <div className="container">
                    <div className='row justify-content-center'>
                        <div className='col-12'>
                            <div className='container-shadow form row'>
                                <div className='col-12 form-heading'>
                                    <h3>
                                        {id !== "0" ? "Modifier la tâche" : "Créer une tâche"}
                                    </h3>
                                    <button className='btn-close' onClick={onRequestClose}/>
                                </div>
                                <Box sx={{width: '100%', marginBottom: '2rem'}}>
                                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Description"/>
                                            {values.id !== "0" && <Tab label="Prestations"/>}
                                        </Tabs>
                                    </Box>
                                </Box>

                                <TabPanel value={value} index={0}>
                                    <SelectWithLabel label="Type de la tâche" options={TYPE_OPTIONS}
                                                     placeholder="Choisir un type"
                                                     values={values} name="type"
                                                     onChange={handleOnSelectChange}/>
                                    <label className='label-component'>
                                        Echéance
                                    </label>
                                    <div className='container-date'>
                                        <DatePicker
                                            isClearable
                                            className="input-text"
                                            onChange={value => handleOnDateChange(value, "deadline")}
                                            dateFormat="MMMM d, yyyy"
                                            tabIndex="6"
                                            locale="fr"
                                            placeholderText="Choisir une échéance"
                                            selected={values["deadline"]}/>
                                    </div>


                                    <label className='label-component'>
                                        Prévision
                                    </label>
                                    <div className='container-date'>
                                        <DatePicker
                                            isClearable
                                            className="input-text"
                                            onChange={value => handleOnDateChange(value, "forecastDate")}
                                            dateFormat="MMMM d, yyyy"
                                            tabIndex="6"
                                            locale="fr"
                                            placeholderText="Choisir une prévision"
                                            selected={values["forecastDate"]}/>
                                    </div>

                                    {!projectId && <SelectWithLabel label="Projet lié à la tâche" options={projectOptions}
                                                                    placeholder="Choisir un projet"
                                                                    values={values} name="projectId"
                                                                    error={errors?.projectId}
                                                                    onChange={handleOnSelectChange}/>}

                                    <SelectWithLabel label="Phase liée à la tâche" options={PROJECT_PHASE_OPTIONS}
                                                     values={values}
                                                     name="phase" placeholder="Choisir une phase"
                                                     onChange={handleOnSelectChange}/>

                                    <SelectWithLabel label="Personne attribuée" options={userOptions}
                                                     placeholder="Choisir un utilisateur"
                                                     values={values} name="userIds" isMulti isClearable
                                                     onChange={handleOnSelectChange}/>
                                    <div className='col-12 btn-group text-center'>
                                        <button className='btn-ternary' onClick={handleOnSubmit}
                                                disabled={isCreating || isUpdating}>enregistrer
                                        </button>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <CustomTable columns={columns} useFetchElement={useGetTimeSheetsQuery} pageSize={5}
                                                 search={{taskId: values.id}} contentElement={containerElement}
                                                 ListRowElement={TimeSheetSimplifiedListRow}
                                                 entityName="TimeSheet"
                                                 createButtonElement={createButtonElement}/>
                                </TabPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        );
    }
;

export default TaskModal;