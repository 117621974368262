import {PERSON_STATUS, VERBAL_REPORT_STATUS} from "../config/constants";
import {getContactTypeAbbreviationLabel, getContactTypeLabel} from "./contact-utils";

export const getPersonStatusLabel = status => {
    switch (status) {
        case PERSON_STATUS.ATTENDING :
            return "Présent";
        case PERSON_STATUS.LATE :
            return "En retard";
        case PERSON_STATUS.MISSING :
            return "Absent";
        case PERSON_STATUS.EXCUSED :
            return "Excusé";
        case PERSON_STATUS.NOT_CONCERNED :
            return "Non concerné";
        default :
            return "Présent";
    }
};

export const getVerbalReportStatusLabel = status => {
    switch (status) {
        case VERBAL_REPORT_STATUS.TO_BE_WRITTEN:
            return "A rédiger";
        case VERBAL_REPORT_STATUS.IN_PROGRESS:
            return "En cours"
        case VERBAL_REPORT_STATUS.COMPLETED:
            return "Terminé"
        case VERBAL_REPORT_STATUS.PUBLISHED:
            return "Publié"
        default :
            return "A rédiger";
    }
};

export const isContactAttendee = attendee => attendee.type !== undefined;

export const getAttendeeLabel = attendee => {
    const attendeeType = isContactAttendee(attendee) ? getContactTypeLabel(attendee.type) : "Dessinateur";
    const attendeeTypeAbbreviation = isContactAttendee(attendee) ? getContactTypeAbbreviationLabel(attendee.type) : "DSS";
    return [attendeeType, attendeeTypeAbbreviation, attendee.name, attendee.surname, attendee.mobileNumber, attendee.emailAddress].join(" - ");
};