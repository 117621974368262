import {CONTACT_TYPE} from "../config/constants";

export const getContactTypeLabel = type => {
    switch (type) {
        case CONTACT_TYPE.PROJECT_OWNER :
            return "Maître d'ouvrage";
        case CONTACT_TYPE.ARCHITECT:
            return "Architecte";
        case CONTACT_TYPE.SURVEYOR:
            return "Géomètre";
        case CONTACT_TYPE.PEB:
            return "Certificateur PEB";
        case CONTACT_TYPE.ENGINEER:
            return "Ingénieur";
        case CONTACT_TYPE.CONTRACTOR:
            return "Entrepreneur";
        case CONTACT_TYPE.DRAWER:
            return "Dessinateur";
        case CONTACT_TYPE.ENERGY_AUDITOR:
            return "Auditeur énergétique";
        case CONTACT_TYPE.SAFETY_COORDINATOR:
            return "Coordinateur de sécurité";
        case CONTACT_TYPE.SPECIALIST_TECHNIQUES:
            return "Techniques spéciales";
        case CONTACT_TYPE.BIM_MANAGER:
            return "BIM Coordinateur/Manager";
        case CONTACT_TYPE.ADMINISTRATION:
            return "Administration";
        case CONTACT_TYPE.PROMOTER:
            return "Promoteur";
        case CONTACT_TYPE.NOTARY:
            return "Notaire";
        case CONTACT_TYPE.SOIL_TESTING:
            return "Essais de sol";
        default:
            return "Maître d'ouvrage";
    }
};

export const getContactTypeAbbreviationLabel = type => {
    switch (type) {
        case CONTACT_TYPE.PROJECT_OWNER :
            return "MO";
        case CONTACT_TYPE.ARCHITECT:
            return "ARCH";
        case CONTACT_TYPE.SURVEYOR:
            return "GEO";
        case CONTACT_TYPE.PEB:
            return "PEB";
        case CONTACT_TYPE.ENGINEER:
            return "ING";
        case CONTACT_TYPE.CONTRACTOR:
            return "ENT";
        case CONTACT_TYPE.DRAWER:
            return "DSS";
        case CONTACT_TYPE.ENERGY_AUDITOR:
            return "AUD";
        case CONTACT_TYPE.SAFETY_COORDINATOR:
            return "COO";
        case CONTACT_TYPE.SPECIALIST_TECHNIQUES:
            return "TECH";
        case CONTACT_TYPE.BIM_MANAGER:
            return "BIM";
        case CONTACT_TYPE.ADMINISTRATION:
            return "ADM";
        case CONTACT_TYPE.PROMOTER:
            return "PRO";
        case CONTACT_TYPE.NOTARY:
            return "NOT";
        case CONTACT_TYPE.SOIL_TESTING:
            return "SOL";
        default:
            return "MO";
    }
};
