import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {isGranted} from "../utils/authentication-util";

const RequireAuth = ({children, permissions}) => {
    let location = useLocation();

    // eslint-disable-next-line no-undef
    if (!abp.session.userId) return <Navigate to="/login" state={{from: location}} replace/>;

    if (permissions && !permissions.every(isGranted)) return <Navigate to="/exception?type=401" state={{from: location}}
                                                                       replace/>;

    return children;
};

export default RequireAuth;