import dayjs from "dayjs";

export const format = (date) => {
    let parsedDate = new Date(date);

    let day = parsedDate.getDate();       // yields date
    if (day < 10) day = `0${day}`;

    let month = parsedDate.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
    if (month < 10) month = `0${month}`;

    let year = parsedDate.getFullYear();  // yields year
    if (year < 10) year = `0${year}`;

    let hour = parsedDate.getHours();     // yields hours
    if (hour < 10) hour = `0${hour}`;

    let minute = parsedDate.getMinutes(); // yields minutes
    if (minute < 10) minute = `0${minute}`;

    let second = parsedDate.getSeconds(); // yields seconds
    if (second < 10) second = `0${second}`;

    let milliseconds = parsedDate.getMilliseconds(); // yields seconds
    if (milliseconds < 10) milliseconds = `0${milliseconds}`;


    return `${year}-${month}-${day}T${hour}:${minute}:${second}.${milliseconds}Z`;
};

export const toUTCString = date => dayjs.utc(date, 'YYYY-MM-DDThh:mm:ss').toDate();

export const toLocaleString = (date, format) => date ? dayjs.utc(date, 'YYYY-MM-DDThh:mm:ss').local().format(format || "LL") : "Date incomplète";