import React from 'react';
import {toLocaleString} from "../../../utils/date-utils";
import {PERMISSION} from "../../../config/constants";
import {useDeleteTimeSheetMutation} from "../../../api/TimeSheetApi";
import DeleteButton from "../../../components/interactive/DeleteButton";

const TimeSheetSimplifiedListRow = ({element: timeSheet}) => {
    const [deleteTimeSheet, {isLoading: isDeleting}] = useDeleteTimeSheetMutation();

    const handleOnClickDelete = async event => {
        event.preventDefault();
        await deleteTimeSheet(timeSheet?.id).unwrap();
    };

    return (
        <tr>
            <td>
                <span className='block-content bold'>
                    {timeSheet?.id}
                </span>
            </td>
            <td>
                <span className='text-slim'>
                    {timeSheet?.creatorUserName} {timeSheet?.creatorUserSurname}
                </span>
            </td>
            <td>
                <span className='text-grey'>
                    {`${timeSheet?.timeSpent || 0}h`}
                </span>
            </td>
            <td>
                <span className='text-slim'>
                    {toLocaleString(timeSheet?.timeSpentDate, "L")}
                </span>
            </td>
            <td>
                <div className="btn-group-right">
                    <DeleteButton onClick={handleOnClickDelete} disabled={isDeleting}
                                  permission={PERMISSION.DELETE_TIME_SHEET}/>
                </div>
            </td>
        </tr>
    );
};

export default TimeSheetSimplifiedListRow;