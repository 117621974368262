import {selectModalIsOpen, selectSelectedProjectId, selectSelectedTaskId} from "../components/Task/taskSlice";
import {useSelector} from "react-redux";
import {useMemo} from "react";

export const useTaskManagement = () => {
    const modalIsOpen = useSelector(selectModalIsOpen);
    const selectedTaskId = useSelector(selectSelectedTaskId);
    const selectedProjectId = useSelector(selectSelectedProjectId);

    return useMemo(() => ({
        modalIsOpen,
        selectedTaskId,
        selectedProjectId
    }), [modalIsOpen, selectedTaskId, selectedProjectId]);
};