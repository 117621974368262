import {useSelector} from "react-redux";
import {useMemo} from "react";
import {
    selectAssignContactModalIsOpen,
    selectModalIsOpen,
    selectSelectedProjectId
} from "../components/Contact/contactSlice";

export const useContactManagement = () => {
    const modalIsOpen = useSelector(selectModalIsOpen);
    const selectedProjectId = useSelector(selectSelectedProjectId);
    const selectedAssignContactModalIsOpen = useSelector(selectAssignContactModalIsOpen);

    return useMemo(() => ({
        modalIsOpen,
        selectedProjectId,
        selectedAssignContactModalIsOpen
    }), [modalIsOpen, selectedProjectId, selectedAssignContactModalIsOpen]);
};