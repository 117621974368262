import React, {useEffect, useState} from 'react';
import {PAGE_SIZE} from "../../config/constants";
import {TablePagination, TableSortLabel} from "@mui/material";
import {createPortal} from "react-dom";
import ExportButton from "../interactive/ExportButton";

const CustomTable = ({
                         contentElement: ContentElement,
                         createButtonElement: CreateButtonElement,
                         summaryElement: SummaryElement,
                         columns,
                         ListRowElement,
                         useFetchElement,
                         search,
                         defaultSorting = "",
                         handleOnRowClick,
                         pageSize = PAGE_SIZE,
                         entityName
                     }) => {
    const [containerButtonsElement, setContainerButtonsElement] = useState(document.getElementById(`container-buttons-${entityName.toLowerCase()}`));
    const [sorting, setSorting] = useState(defaultSorting);
    const [order, setOrder] = React.useState('asc');
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        setContainerButtonsElement(document.getElementById(`container-buttons-${entityName.toLowerCase()}`));
    }, [document.getElementById(`container-buttons-${entityName.toLowerCase()}`)]);


    const {data} = useFetchElement({
        ...search,
        skipCount: currentPage * pageSize,
        maxResultCount: pageSize,
        sorting: sorting ? sorting.split(",").map(sort => `${sort} ${order.toUpperCase()}`).join(",") : ""
    });

    const handleOnClick = event => {
        event.preventDefault();
        const _sorting = event.target.getAttribute("data-sorting");
        const isAsc = sorting === _sorting && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setSorting(_sorting);
    };

    const identifiers = data?.items?.map(element => element.id);

    return (<ContentElement>
            <table className='table-primary'>
                <thead>
                <tr>
                    {columns.map(column =>
                        <th key={`custom-table-header-${column.name.toLowerCase()}`} className={column.className}>
                            {!!column.sorting ?
                                <TableSortLabel active={sorting === column.sorting} data-sorting={column.sorting}
                                                direction={sorting === column.sorting ? order : 'asc'}
                                                onClick={handleOnClick}>
                                    {column.name}
                                </TableSortLabel> : column.name}
                        </th>
                    )}
                </tr>
                </thead>
                <tbody>
                {data?.items?.map(element => <ListRowElement key={`custom-table-row-${element.id}`}
                                                             element={element} handleOnRowClick={handleOnRowClick}/>)}

                {(data?.items?.length > 0 && SummaryElement) && <SummaryElement items={data?.items || []}/>}

                {CreateButtonElement && <tr>
                    <td colSpan={columns.length}>
                        <CreateButtonElement {...search}/>
                    </td>
                </tr>}
                </tbody>
            </table>

            {containerButtonsElement && createPortal(
                <ExportButton entityName={entityName} identifiers={identifiers}
                              isExportAll={false}/>, containerButtonsElement)}

            <TablePagination component="div" count={data?.totalCount || 0} page={currentPage} rowsPerPage={pageSize}
                             rowsPerPageOptions={[]}
                             onPageChange={(event, newPage) => setCurrentPage(newPage)}/>
        </ContentElement>
    );
};


CustomTable.defaultProps = {
    contentElement: ({children}) => <section className='container-section row w-100 pl-15'>
        <div className='col-12'>{children}</div>
    </section>
};

export default CustomTable;