import {useEffect, useState} from "react";

function useFormValidation(initialState, validate, handler) {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (isSubmitting) {
            const noErrors = Object.keys(errors).length === 0;
            if (noErrors) {
                handler(values);
                setSubmitting(true);
            } else {
                setSubmitting(false);
            }
        }
    }, [errors]);

    const handleOnChange = event => {
        setValues({...values, [event.target.name]: event.target.value, isUpdated: true});
    };

    const handleOnSelectChange = (newValue, actionMeta) => {
        const value = Array.isArray(newValue) ? newValue?.map(v => v.value) : newValue?.value;
        setValues({...values, [actionMeta.name]: value, isUpdated: true});
    };

    const handleOnDateChange = (value, name) => {
        setValues({...values, [name]: value, isUpdated: true});
    };

    const handleOnSubmit = event => {
        event.preventDefault();
        const validationErrors = validate(values);
        setErrors(validationErrors);
        setSubmitting(true);
        setValues({...values, isUpdated: false});
    };

    return {
        handleOnChange,
        handleOnSubmit,
        handleOnSelectChange,
        handleOnDateChange,
        setValues,
        values,
        errors,
        isSubmitting
    };
}

export default useFormValidation;