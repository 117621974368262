import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'contactManagement',
    initialState: {modalIsOpen: false, selectedProjectId: undefined, assignContactModalIsOpen: false},
    reducers: {
        setContactModalIsOpen: (state, {payload}) => {
            state.modalIsOpen = payload
        },
        setAssignContactModalIsOpen: (state, {payload}) => {
            state.assignContactModalIsOpen = payload
        },
        setSelectedProjectId: (state, {payload}) => {
            state.selectedProjectId = payload
        }
    }
});

export default slice.reducer;

export const {setContactModalIsOpen, setSelectedProjectId, setAssignContactModalIsOpen} = slice.actions;

export const selectModalIsOpen = state => state.contactManagement.modalIsOpen;

export const selectSelectedProjectId = state => state.contactManagement.selectedProjectId;

export const selectAssignContactModalIsOpen = state => state.contactManagement.assignContactModalIsOpen;