import React, {useEffect} from 'react';
import "../assets/scss/app.scss";
import SynappRoutes from "../routes";
import {useGetSessionMutation} from "../api/AuthenticationApi";
import {ToastContainer} from "react-toastify";
import Modal from 'react-modal';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr";
import TaskModal from "../components/Task/TaskModal";
import {useTaskManagement} from "../hooks/useTaskManagement";
import {useDispatch} from "react-redux";
import {setModalIsOpen, setSelectedProjectId, setSelectedTaskId} from "../components/Task/taskSlice";
import {setAssignContactModalIsOpen, setContactModalIsOpen} from "../components/Contact/contactSlice";
import CreateContactModal from "../components/Contact/CreateContactModal";
import {useContactManagement} from "../hooks/useContactManagement";
import AssignContactModal from "../components/Contact/AssignContactModal";
import {useEventManagement} from "../hooks/useEventManagement";
import {setEventModalIsOpen, setEventSelectedProjectId} from "../components/Event/eventSlice";
import CreateEventModal from "../components/Event/CreateEventModal";

Modal.setAppElement('#root');

registerLocale("fr", fr);

const App = () => {
    const [getSession] = useGetSessionMutation();
    const {i18n} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const {selectedTaskId, modalIsOpen, selectedProjectId} = useTaskManagement();
    const {
        modalIsOpen: contactModalIsOpen,
        selectedProjectId: selectedContactProjectId,
        selectedAssignContactModalIsOpen: assignContactModalIsOpen
    } = useContactManagement();

    const {
        modalIsOpen: eventModalIsOpen,
        selectedProjectId: selectedEventProjectId
    } = useEventManagement();

    useEffect(() => {
        getSession();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // eslint-disable-next-line no-undef
    abp.utils.setCookieValue(
        'Abp.Localization.CultureName',
        i18n.language,
        new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
        // eslint-disable-next-line no-undef
        abp.appPath
    );

    const handleOnClickCloseButton = event => {
        if (event) event.preventDefault();
        dispatch(setModalIsOpen(false));
        dispatch(setContactModalIsOpen(false));
        dispatch(setEventModalIsOpen(false));
        dispatch(setAssignContactModalIsOpen(false));
        dispatch(setSelectedTaskId("0"));
        dispatch(setSelectedProjectId(undefined));
        dispatch(setEventSelectedProjectId(undefined));
    };

    return (
        <>
            <SynappRoutes/>
            <ToastContainer hideProgressBar position="bottom-right"/>
            <TaskModal isOpen={modalIsOpen} onRequestClose={handleOnClickCloseButton} id={selectedTaskId}
                       projectId={selectedProjectId}/>
            <CreateContactModal isOpen={contactModalIsOpen} onRequestClose={handleOnClickCloseButton}
                                projectId={selectedContactProjectId}/>
            <AssignContactModal isOpen={assignContactModalIsOpen} onRequestClose={handleOnClickCloseButton}
                                projectId={selectedContactProjectId}/>
            <CreateEventModal isOpen={eventModalIsOpen} onRequestClose={handleOnClickCloseButton}
                                projectId={selectedEventProjectId}/>
        </>
    );
};

export default App;