import {STATUS} from "../config/constants";

export const getBadgeLabel = status => {
    switch (status) {
        case STATUS.TO_DO:
            return "A faire";
        case STATUS.IN_PROGRESS:
            return "En cours";
        case STATUS.URGENT:
            return "Urgent";
        case STATUS.WAITING:
            return "En attente";
        case STATUS.DONE:
            return "Fait";
        default:
            return "A faire";
    }
};

export const getBadgeClass = status =>{
    switch (status) {
        case STATUS.TO_DO:
            return "orange";
        case STATUS.IN_PROGRESS:
            return "cyan";
        case STATUS.URGENT:
            return "red";
        case STATUS.WAITING:
            return "yellow";
        case STATUS.DONE:
            return "green";
        default:
            return "orange";
    }
};